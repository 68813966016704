import React from 'react'
import LayoutStatic from '../components/LayoutStatic'
import TextParser from '../components/TextParser'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import Moment from 'react-moment'
import 'moment-timezone'

import arrowLeftDouble from '../img/angle-double-left-regular.svg'
import arrowLeft from '../img/angle-left-regular.svg'
import arrowRightDouble from '../img/angle-double-right-regular.svg'
import arrowRight from '../img/angle-right-regular.svg'

Moment.globalTimezone = 'Asia/Tokyo'
Moment.globalFormat = 'YYYY.M.D'

class PostList extends React.Component {

  getTextFromIntl = (key) => {
    return this.props.intl.formatMessage({ id: key })
  }

  render() {

    const { currentPage, numPages, categorySlug, firstPagePath } = this.props.pageContext

    const category = this.props.data.site.siteMetadata.postCategories
    const categoryForLocale = category[categorySlug][this.props.intl.locale]
    const categoryCommon = category[categorySlug].common

    const locale = this.props.intl.locale

    const paged = currentPage
		const max_page = numPages
		let pstart = paged - 3
		let pend = paged + 3
		if (pstart < 1) pstart = 1
		if (pend > max_page) pend = max_page

    const pages = []
    for (let i = pstart; i <= pend; i++) {
      pages.push(i)
    }
  
    return (
        <LayoutStatic {...this.props} title="NEWS">
        { categoryForLocale.length != 0 ? 
          <h2><span title={categoryForLocale}>{categoryCommon}</span></h2>
          : 
          <h2>{categoryCommon}</h2>
        }
        { this.props.data.allMarkdownRemark.edges.map(({ node }, index) => (
          <React.Fragment key={index}>
          <article className="list">
            <div className="date"><img src="/images/paper-plane-solid.svg" alt="News" className="newsIcon" /><Moment>{node.frontmatter.date}</Moment></div>
            <h3><Link to={node.frontmatter.path}>{node.frontmatter.title[locale]}</Link></h3>
            <TextParser intl={this.props.intl} text={node.html} />
          </article>
          <hr className="list" />
          </React.Fragment>
        ))}
        { max_page > 1 ? 
          <nav className="navigationList">
		      { pstart != 1 ? <Link to={firstPagePath}><img src={arrowLeftDouble} alt={this.getTextFromIntl('paging.firstPage')} /></Link> : null }
          { paged != 1 ? 
            (paged - 1 === 1 ? 
              <Link to={firstPagePath}><img src={arrowLeft} alt={this.getTextFromIntl('paging.previousPage')} /></Link>
              :
              <Link to={`${firstPagePath}/page/${paged - 1}`}><img src={arrowLeft} alt={this.getTextFromIntl('paging.previousPage')} /></Link>
            )
            :
            null
          }
          { pages.map(index => (
            index != paged ? 
              (index === 1 ? 
                <Link key={index} to={firstPagePath}>{index}</Link>
                :
                <Link key={index} to={`${firstPagePath}/page/${index}`}>{index}</Link>
              )
              :
              <span key={index} className="current">{index}</span>
          ))}
          { paged != max_page ? <Link to={`${firstPagePath}/page/${paged + 1}`}><img src={arrowRight} alt={this.getTextFromIntl('paging.nextPage')} /></Link> : null }
          { pend != max_page ? <Link to={`${firstPagePath}/page/${max_page}`}><img src={arrowRightDouble} alt={this.getTextFromIntl('paging.lastPage')} /></Link> : null }
          </nav>
          :
          null
        }
        </LayoutStatic>
    )
  }
}

export const newsListQuery = graphql`
  query newsListQuery($skip: Int!, $limit: Int!, $categorySlug: String!) {
    site {
      siteMetadata {
        postCategories {
          news {
            common
            ja
            en
          }
          faq {
            common
            ja
            en
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: { category : {eq: $categorySlug}}} 
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          html
          frontmatter {
            title {
              ja
              en
            }
            path
            date
            category
          }
        }
      }
    }
  }
`

export default injectIntl(PostList)

