import React from 'react'
import parse, { domToReact } from 'html-react-parser'
import { Link } from 'gatsby-plugin-intl'

export const parseText = (text, intl) => {
    if (!text || !intl) return null;

    return parse(text, {
        replace: ({ attribs, name, children }) => {
            if (attribs && attribs.class) {
                attribs.className = attribs.class
                delete attribs.class
            }
            if (attribs && attribs.lang) {
                if (attribs.lang !== intl.locale) return <></>;
                delete attribs.lang
            }
            if (name === 'a') {
                const href = attribs.href
                if (href && href.charAt(0) === '/') {
                    return <Link to={href} {...attribs}>{domToReact(children)}</Link>
                }
            }
        }
    })
}
