import { Component } from 'react'
import { parseText } from '../classes/ParseText'

class TextParser extends Component {
    render() {
        const text = this.props.translationKey ? this.props.intl.formatMessage({ id: this.props.translationKey }) : this.props.text
        return (
            parseText(text, this.props.intl)
        )
    }
}

export default TextParser
